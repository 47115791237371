@import "styles/variables.scss";
.switcher {
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  .active {
    color: $primary;
    font-weight: bolder;
    position: relative;
  }
}
.switcherHomePage {
  .active {
    color: $primary;
    font-weight: bolder;
    position: relative;
    & > span {
      position: relative;
    }
    & > span:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background: $primary;
      bottom: -5px;
      left: 0;
    }
  }
}
.switcherFromSearchBox {
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;
  height: 27px;
  & > div:nth-of-type(1) > .span:nth-of-type(1) {
    width: 51px;
  }
  & > div:nth-of-type(1) > .span:nth-of-type(1):after {
    content: '';
    height: 50%;
    width: 1px;
    position: absolute;
    right: -10px;
    top: 25%;
    background-color: $white;
  }
  & > div > span {
    font-size: 16px;
    border-bottom: 3px solid transparent;
    position: relative;
  }
  .active {
    color: $white;
    & > span {
      border-bottom: 3px solid $primary-light;
    }
  }
}
