@import "styles/variables.scss";
.fixed {
  position: fixed;
  right: 10px;
  bottom: 10px;
  min-height: 2em;
  z-index: 2;
}

.toastBody {
  display: flex;
  justify-content: center;
}

.btn {
  width: 135px;
  height: 100%;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  background-color: $primary;
  border: none;
  font-size: 1rem;
  color: $white;
  padding: 0;
}
